<template>
  <div class="village_list">
    <div class="wrapper">
      <a-spin :spinning="loading">
        <div v-if="list.length > 0" class="list">
          <div class="item" v-for="(item, index) in list" :key="index">
            <div class="title">
              <a-icon type="team" />
              <router-link
                :to="{
                  name: 'village_organization_detail',
                  params: { id: item.villageId },
                }"
                >{{
                  item.organizationName ||
                  item.village.countryName +
                    item.village.villageName +
                    item.principalName
                }}</router-link
              >
            </div>
            <!--<div class="info">
              <div class="detail">
                <p>主要服务品种：设施蔬菜、菌类、玉米、大豆、马铃薯</p>
                <p>主要服务类型：技术指导、旋耕、深耕</p>
              </div>
              <div class="btn">
                <a-button
                  type="primary"
                  @click="
                    $router.push({
                      name: 'village_organization_detail',
                      params: { id: item.villageInfoId },
                    })
                  "
                  >详情</a-button
                >
              </div>
            </div>-->
          </div>
          <custom-page
            @change="change"
            :current="pageNum"
            :total="total"
          ></custom-page>
        </div>
        <a-empty v-else></a-empty>
      </a-spin>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Api from "@/api/organization";
export default {
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
    };
  },
  components: {},
  props: {
    keyword: { type: String },
    region: { type: [Number, String], default: () => null },
  },
  watch: {
    keyword() {
      this.pageNum = 1;
      this.getList();
    },
    region() {
      this.pageNum = 1;
      this.getList();
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      Api.listVillage({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        organizationName: this.keyword,
        areaCode: this.region,
      }).then((res) => {
        this.loading = false;
        this.list = res.result.records;
        this.total = res.result.total;
      });
    },
    change(page) {
      this.pageNum = page;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.switch-region {
  line-height: 30px;
  color: #444;
  margin-bottom: 10px;
  span,
  a {
    cursor: pointer;
    display: inline-block;
    padding: 0 10px;
    text-align: center;
    margin-left: 10px;
    &.active,
    &.router-link-exact-active {
      background: #008840;
      color: white;
    }
  }
}
.wrapper {
  min-height: 300px;
}
.list {
  .item {
    margin-top: 20px;
    .title {
      line-height: 36px;
      font-size: 16px;
      background-color: #f4f5f9;
      padding-left: 10px;
      a {
        margin-left: 10px;
      }
    }
    .info {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      .btn {
        padding-top: 11px;
      }
      p {
        margin: 0;
        line-height: 2em;
      }
    }
  }
}
</style>
